import {useContext, useEffect} from 'react'
import Spinner from '../layout/Spinner'
import DealItem from  '../deals/DealItem'
import ECContext from '../../context/deal-assignments/ECContext'
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";




function isShow(deal, showOnlyPartlyAssigned) {

  const _trades = []

  const crewRBuildDate = deal.UF_CRM_1610705339
  const crewSBuildDate = deal.UF_CRM_1610877006
  const crewWBuildDate = deal.UF_CRM_1610877523
  const crewGBuildDate = deal.UF_CRM_1610877363
  const crewFBuildDate = deal.UF_CRM_1610877224
  const crewDBuildDate = deal.UF_CRM_1610877813
  const crewIBuildDate = deal.UF_CRM_1610877927
  const crewWWBuildDate = deal.UF_CRM_1611841827

  const scopeOfWork = Object.values(deal.UF_CRM_1608388387)

  // if(deal.ID == 9479) {
  //    console.log("SOW:")
  //    console.log(scopeOfWork)
  // }

  let tempBuildDate = ""
  let tempTradeType = ""

  if(crewRBuildDate !== "") {
    tempBuildDate = crewRBuildDate
    tempTradeType = "Roof"

    const trade = {type: "Roof", buildDate: convertDate(crewRBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(44) !== -1) {
    const trade = {type: "Roof", buildDate: ""}
    _trades.push(trade)
  }


  if(crewSBuildDate !== "") {
    tempBuildDate = crewSBuildDate
    tempTradeType = "Siding"

    const trade = {type: "Siding", buildDate: convertDate(crewSBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(50) !== -1) {
    const trade = {type: "Siding", buildDate: ""}
    _trades.push(trade)
  }



  if(crewWBuildDate !== "") {
    tempBuildDate = crewWBuildDate
    tempTradeType = "Windows"

    const trade = {type: "Windows", buildDate: convertDate(crewWBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(52) !== -1) {
    const trade = {type: "Windows", buildDate: ""}
    _trades.push(trade)
  }



  if(crewGBuildDate !== "") {
    tempBuildDate = crewGBuildDate
    tempTradeType = "Gutters"

    const trade = {type: "Gutters", buildDate: convertDate(crewGBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(54) !== -1) {
    const trade = {type: "Gutters", buildDate: ""}
    _trades.push(trade)
  }



  if(crewFBuildDate !== "") {
    tempBuildDate = crewFBuildDate
    tempTradeType = "Fascia"

    const trade = {type: "Fascia", buildDate: convertDate(crewFBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(56) !== -1) {
    const trade = {type: "Fascia", buildDate: ""}
    _trades.push(trade)
  }


  if(crewDBuildDate !== "") {
    tempBuildDate = crewDBuildDate
    tempTradeType = "Deck"

    const trade = {type: "Deck", buildDate: convertDate(crewDBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(58) !== -1) {
    const trade = {type: "Deck", buildDate: ""}
    _trades.push(trade)
  }


  if(crewIBuildDate !== "") {
    tempBuildDate = crewIBuildDate
    tempTradeType = "Interior"

    const trade = {type: "Interior", buildDate: convertDate(crewIBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(60) !== -1) {
    const trade = {type: "Interior", buildDate: ""}
    _trades.push(trade)
  }


  if(crewWWBuildDate !== "") {
    tempBuildDate = crewWWBuildDate
    tempTradeType = "Window Wraps"

    const trade = {type: "Window Wraps", buildDate: convertDate(crewWWBuildDate)}
    _trades.push(trade)
  }
  else if(scopeOfWork.indexOf(319) !== -1) {
    const trade = {type: "Window Wraps", buildDate: ""}
    _trades.push(trade)
  }


  const tradesWithEmptyBuildDates = _trades.filter((trade) => trade.buildDate === "")
  const totalUnasignedTrades =  Object.keys(tradesWithEmptyBuildDates).length

  const tradesWithNoneEmptyBuildDates = _trades.filter((trade) => trade.buildDate !== "")
  const totalAssighenTrades =  Object.keys(tradesWithNoneEmptyBuildDates).length


  let needToShow = false

  if (showOnlyPartlyAssigned === true && totalUnasignedTrades > 0 && totalAssighenTrades > 0)
    needToShow = true
  else if (showOnlyPartlyAssigned === false)
    needToShow = true


  return needToShow;
}

function convertDate(tempBuildDate) {

  if(tempBuildDate !== "") {


    //let x = "2011-01-28T19:30:00EST"
    //let x =   "2021-11-16T09:00:00+03:00"
    let x = tempBuildDate

    let MM = {Jan:"January", Feb:"February", Mar:"March", Apr:"April", May:"May", Jun:"June", Jul:"July", Aug:"August", Sep:"September", Oct:"October", Nov:"November", Dec:"December"}

    let xx = String(new Date(x)).replace(
       /\w{3} (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):[^(]+\(([A-Z]{3})\)/,
       function($0,$1,$2,$3,$4,$5,$6){
         return MM[$1]+" "+$2+", "+$3+" - "+$4%12+":"+$5+(+$4>12?"PM":"AM")+" "+$6
       }
    )
    // console.log("xx:")
    // console.log(xx)

    const formattedDate = new Date(xx)
    // console.log("new date:")
    // console.log(formattedDate.getFullYear())

    // console.log('Title:')
    // console.log(deal.TITLE)

    //tempBuildDate = `${formattedDate.getMonth()}/${formattedDate.getDay()}/${formattedDate.getFullYear()}`
    tempBuildDate = formattedDate.toDateString()

    return tempBuildDate;


    // console.log('Filan data')
    // console.log(tempBuildDate)

  }
}


/*
function getTradesIDsFromDeal(deal) {
  let dealTrades = []


  return dealTrades
}
*/



function DealResults() {

  const { users, loading, filterStage, showOnlyPartlyAssigned } = useContext(ECContext)



  if (!loading) {

    // console.log('====== DEALS DATA TO TRANSFORM')
    // console.log(users)

    const dealsData = users
    const tradeData = []

    let i = 1

// console.log('dealsData:')
// console.log(dealsData)

    dealsData.forEach((deal) => {

      const dealTrades = deal.UF_CRM_1608388387
      if(dealTrades == false) {
//console.log('====== empty deal list')

        return (
           <div className='grid grid-cols-1 gap-1 z-0 pt-20 ' id='deals'>
           </div>
        )

      }
        //return;

// console.log('====== dealTrades (dealId: ' + deal.ID)
// console.log(dealTrades)



      dealTrades.forEach((trade, index, allDealTrades) => {

        const checkTradeStatus = (filterStage, trade, dealData) => {

          if(trade == 44 && deal.UF_CRM_1671298235 == 1) {       //Roofing && 'Scope Agreed/Ready to Order (R)' should be equal 1

            if( (filterStage == 1853) && (dealData.UF_CRM_1670943824 == '1763')) //'Ready to Order' и 'Status (R)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670943824 == '1769')) //'Crew Scheduled' и 'Status (R)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670943824 == '1771')) //'Completed' и 'Status (R)'
              return true

            return false;
          }
          else if(trade === 50 && deal.UF_CRM_1671298263 == 1) {  //Siding

            if( (filterStage == 1853) && (dealData.UF_CRM_1670944138 == '1773')) //'Ready to Order' и 'Status (S)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944138 == '1779')) //'Crew Scheduled' и 'Status (S)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944138 == '1781')) //'Completed' и 'Status (S)'
              return true

            return false;
          }
          else if(trade === 52 && deal.UF_CRM_1671298287 == 1) {  //Windows

            if( (filterStage == 1853) && (dealData.UF_CRM_1670944194 == '1783')) //'Ready to Order' и 'Status (W)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944194 == '1789')) //'Crew Scheduled' и 'Status (W)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944194 == '1791')) //'Completed' и 'Status (W)'
              return true

            return false;
          }
          else if(trade === 319 && deal.UF_CRM_1671298312 == 1) { //Window Wraps

            if( (filterStage == 1853) && (dealData.UF_CRM_1670944299 == '1793')) //'Ready to Order' и 'Status (WW)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944299 == '1799')) //'Crew Scheduled' и 'Status (WW)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944299 == '1801')) //'Completed' и 'Status (WW)'
              return true

            return false;
          }
          else if(trade === 54 && deal.UF_CRM_1671298365 == 1) {  //Gutters
            if( (filterStage == 1853) && (dealData.UF_CRM_1670944392 == '1803')) //'Ready to Order' и 'Status (G)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944392 == '1809')) //'Crew Scheduled' и 'Status (G)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944392 == '1811')) //'Completed' и 'Status (G)'
              return true

            return false;

          }
          else if(trade === 56 && deal.UF_CRM_1671298388 == 1) {  //Fascia
            if( (filterStage == 1853) && (dealData.UF_CRM_1670944510 == '1813')) //'Ready to Order' и 'Status (F)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944510 == '1819')) //'Crew Scheduled' и 'Status (F)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944510 == '1821')) //'Completed' и 'Status (F)'
              return true

            return false;

          }
          else if(trade === 58 && deal.UF_CRM_1671298415 == 1) {  //Deck
            if( (filterStage == 1853) && (dealData.UF_CRM_1670944578 == '1823')) //'Ready to Order' и 'Status (D)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944578 == '1829')) //'Crew Scheduled' и 'Status (D)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944578 == '1831')) //'Completed' и 'Status (D)'
              return true

            return false;

          }
          else if(trade === 60 && deal.UF_CRM_1671298434 == 1) { //Interior
            if( (filterStage == 1853) && (dealData.UF_CRM_1670944681 == '1833')) //'Ready to Order' и 'Status (I)'
              return true
            if( (filterStage == 1859) && (dealData.UF_CRM_1670944681 == '1839')) //'Crew Scheduled' и 'Status (I)'
              return true
            if( (filterStage == 1861) && (dealData.UF_CRM_1670944681 == '1841')) //'Completed' и 'Status (I)'
              return true

            return false;

          }
          else
            return false


        }

        const getTradeStatus = (tradeId, dealData) => {
          if(tradeId == 44) {       //Roofing

            if( (dealData.UF_CRM_1670943824 == '1763')) //'Ready to Order' и 'Status (R)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670943824 == '1769')) //'Crew Scheduled' и 'Status (R)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670943824 == '1771')) //'Completed' и 'Status (R)'
              return 'Completed'

            return '';
          }
          else if(tradeId === 50) {  //Siding

            if( (dealData.UF_CRM_1670944138 == '1773')) //'Ready to Order' и 'Status (S)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944138 == '1779')) //'Crew Scheduled' и 'Status (S)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944138 == '1781')) //'Completed' и 'Status (S)'
              return 'Completed'

            return '';
          }
          else if(tradeId === 52) {  //Windows

            if( (dealData.UF_CRM_1670944194 == '1783')) //'Ready to Order' и 'Status (W)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944194 == '1789')) //'Crew Scheduled' и 'Status (W)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944194 == '1791')) //'Completed' и 'Status (W)'
              return 'Completed'

            return '';
          }
          else if(tradeId === 319) { //Window Wraps

            if( (dealData.UF_CRM_1670944299 == '1793')) //'Ready to Order' и 'Status (WW)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944299 == '1799')) //'Crew Scheduled' и 'Status (WW)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944299 == '1801')) //'Completed' и 'Status (WW)'
              return 'Completed'

            return '';
          }
          else if(tradeId === 54) {  //Gutters
            if( (dealData.UF_CRM_1670944392 == '1803')) //'Ready to Order' и 'Status (G)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944392 == '1809')) //'Crew Scheduled' и 'Status (G)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944392 == '1811')) //'Completed' и 'Status (G)'
              return 'Completed'

            return '';

          }
          else if(tradeId === 56) {  //Fascia
            if( (dealData.UF_CRM_1670944510 == '1813')) //'Ready to Order' и 'Status (F)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944510 == '1819')) //'Crew Scheduled' и 'Status (F)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944510 == '1821')) //'Completed' и 'Status (F)'
              return 'Completed'

            return '';

          }
          else if(tradeId === 58) {  //Deck
            if( (dealData.UF_CRM_1670944578 == '1823')) //'Ready to Order' и 'Status (D)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944578 == '1829')) //'Crew Scheduled' и 'Status (D)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944578 == '1831')) //'Completed' и 'Status (D)'
              return 'Completed'

            return '';

          }
          else if(tradeId === 60) { //Interior
            if( (dealData.UF_CRM_1670944681 == '1833')) //'Ready to Order' и 'Status (I)'
              return 'Ready to Order'
            if( (dealData.UF_CRM_1670944681 == '1839')) //'Crew Scheduled' и 'Status (I)'
              return 'Crew Scheduled'
            if( (dealData.UF_CRM_1670944681 == '1841')) //'Completed' и 'Status (I)'
              return 'Completed'

            return '';

          }
          else
            return '';
        }

        const getTradeStatusDate = (tradeId, dealData) => {
          if(tradeId == 44) {       //Roofing
              return dealData.UF_CRM_1671277904
          }
          else if(tradeId === 50) {  //Siding
              return dealData.UF_CRM_1671277959
          }
          else if(tradeId === 52) {  //Windows
            return dealData.UF_CRM_1671277985
          }
          else if(tradeId === 319) { //Window Wraps
            return dealData.UF_CRM_1671278032
          }
          else if(tradeId === 54) {  //Gutters
            return dealData.UF_CRM_1671278067
          }
          else if(tradeId === 56) {  //Fascia
            return dealData.UF_CRM_1671278092
          }
          else if(tradeId === 58) {  //Deck
            return dealData.UF_CRM_1671278120
          }
          else if(tradeId === 60) { //Interior
            return dealData.UF_CRM_1671278146
          }
          else
            return '';
        }

        if(checkTradeStatus(filterStage, trade, deal) || filterStage === '' ) {

          let tmpTrade = {}
          let copyAllTrades = [...allDealTrades]

          //tmpTrade.id = `${trade}_${deal.ID}_${i++}`
          //tmpTrade.id = `${deal.ID}_${trade}_####_${i++}`
          tmpTrade.id = `${deal.ID}-${trade}`
          tmpTrade.tradeId = trade
          copyAllTrades.splice(copyAllTrades.indexOf(trade), 1)
          tmpTrade.linkedTrades = copyAllTrades
          tmpTrade.linkedDealId = deal.ID
          tmpTrade.linkedDealData = deal
          tmpTrade.linkedDealTitle = deal.TITLE
          tmpTrade.tradeStatus = getTradeStatus(trade, deal)

          let tradeStatusDateTmp = getTradeStatusDate(trade, deal)

          if(tradeStatusDateTmp != undefined && tradeStatusDateTmp != '' ) {
            const tradeStatusDateTmp1 = new Date(tradeStatusDateTmp)
            tmpTrade.tradeStatusDate = tradeStatusDateTmp1.toLocaleDateString()
          }
          else
            tmpTrade.tradeStatusDate = ''

          tradeData.push(tmpTrade)
        }



      })

      //tradeData.push(deal)
    })

    // console.log('====== TRADES DATA')
    // console.log(tradeData)

console.log('tradeData.length:')
console.log(tradeData.length)

    if(tradeData.length === 0) {

      return (
         <div className='grid grid-cols-1 gap-1 z-0 pt-20 min-w-full' id='deals'>
           <div draggable={false} className='card compact side bg-base-100' >
             <div className='flex-row items-center space-x-4  '>

               <div className='w-screen'>
                 {`empty...`}
               </div>
             </div>
           </div>
         </div>
      )
    }

    return (

        <div className='grid grid-cols-1 gap-1 z-0 pt-20 ' id='deals'>

          {tradeData.map((trade, index) => (

              /*`${trade.id} `*/
              <DealItem key={trade.id} prop={ {trades: {trade} } } />
              /*<DealItem key={trade.id} prop={ {trades: {trade} } } />*/

          ))}
        </div>

/*      <div className='grid grid-cols-1 gap-1' id='deals'>

        {users.map((user) => (

            isShow(user, showOnlyPartlyAssigned) === true
             ? <DealItem key={user.ID} prop={ {deals: {user}, showOnlyPartlyAssigned: {showOnlyPartlyAssigned}} } />
             : ""

        ))}
      </div>*/

    )
  } else {
    return <Spinner />
  }
}

export default DealResults
