import axios from 'axios'

const GITHUB_URL = process.env.REACT_APP_GITHUB_URL
const GITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN

const github = axios.create({
  baseURL: GITHUB_URL,
/*  headers: { Authorization: `token ${GITHUB_TOKEN}` },*/
})

// Get search results (deals)
export const searchUsers = async (text, stage, onlyPartlyAssigned = false) => {

    let totalDealsInfo = []

    //let url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=${stage}`
    // https://estateclaim.bitrix24.com/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[!UF_CRM_1616854241]=1&filter[STAGE_ID]=C2:PREPARATION&filter[%TITLE]=Aydin

    //console.log('stage = ' + stage)

    let url = ''
    if(stage != '')
        url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[UF_CRM_1671018359][0]=${stage}`
    else
         url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC`


    if (text !== "")
        url = url + `&filter[%TITLE]=${text}`
    let response = await github.get(url)
    //totalDealsInfo = [...response.data.result]

    // url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=C2:PREPARATION`
    // if (text !== "")
    //     url = url + `&filter[%TITLE]=${text}`
    // response = await github.get(url)
    // totalDealsInfo.push(response.data.result)
    //
    // url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=C2:EXECUTING`
    // if (text !== "")
    //     url = url + `&filter[%TITLE]=${text}`
    // response = await github.get(url)
    // totalDealsInfo.push(response.data.result)




  //const response = await github.get(`/rest/4/bcn7t016d5tuehkp/crm.deal.list.json`)
  //const response = await github.get(`/rest/4/bcn7t016d5tuehkp/crm.deal.list.json`)



  //return response.data.items
  return response.data.result
  //return totalDealsInfo
}

export const getTradesForKanban = async (text = '') => {

    let totalDealsInfo = []

    //let url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[STAGE_ID]=${stage}`
    // https://estateclaim.bitrix24.com/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&filter[!UF_CRM_1616854241]=1&filter[STAGE_ID]=C2:PREPARATION&filter[%TITLE]=Aydin

    let url = ''
    //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC`


// заберём по 50 сделок с каждому из статусов сделок: 'Ready to Order', 'Crew Scheduled' и 'Completed'

    //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''`//&FILTER[ID]=9729`
    //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[STAGE_D]='C2:NEW'`//&FILTER[ID]=9729`
    url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[UF_CRM_1671018359]=1853`//&FILTER[ID]=9729`
    if (text !== "")
        url = url + `&filter[%TITLE]=${text}`
    let response = await github.get(url)
   const dealsData1 = [...response.data.result]

   //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[STAGE_D]='C2:PREPARATION'`//&FILTER[ID]=9729`
   url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[UF_CRM_1671018359]=1859`//&FILTER[ID]=9729`
   //C2:EXECUTING C2:WON C2:LOSE
   if (text !== "")
      url = url + `&filter[%TITLE]=${text}`
   response = await github.get(url)
   const dealsData2 = [...response.data.result]

   //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[STAGE_D]='C2:EXECUTING'`//&FILTER[ID]=9729`
   url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[UF_CRM_1671018359]=1861`//&FILTER[ID]=9729`
   //C2:WON C2:LOSE
   if (text !== "")
      url = url + `&filter[%TITLE]=${text}`
   response = await github.get(url)
   const dealsData3 = [...response.data.result]


   let dealsData =  (dealsData1.concat(dealsData2)).concat(dealsData3)
   dealsData = dealsData.filter( (item,index) => {
      return (dealsData.indexOf(item) == index)
   })

   // console.log("dealsDATA:")
   // console.log(dealsData)



   // url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[STAGE_D]='C2:WON'`//&FILTER[ID]=9729`
   // if (text !== "")
   //    url = url + `&filter[%TITLE]=${text}`
   // response = await github.get(url)
   // dealsData = dealsData.concat(response.data.result)
   //
   // url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC&FILTER[CATEGORY_ID]=2&FILTER[!UF_CRM_1671018359]=''&FILTER[STAGE_D]='C2:LOSE'`//&FILTER[ID]=9729`
   // if (text !== "")
   //    url = url + `&filter[%TITLE]=${text}`
   // response = await github.get(url)
   // dealsData = dealsData.concat(response.data.result)





   /*
   const tempTradeData = [...response.data.result]
   console.log('loaded trades data:')
   console.log(tempTradeData)
   */









   let tradesCardData = {
      columns: [
         {
            id: 0,
            title: "Project Inbox",
            backgroundColor: "#fff",
            cards: []
         },
         {
            id: 1,
            title: "Ready to Order",
            backgroundColor: "#fff",
            cards: []
         },
         {
            id: 2,
            title: "Materials Ordered",
            cards: []
         },
         {
            id: 3,
            title: "Materials Delivered",
            cards: []
         },
         {
            id: 4,
            title: "Crew Scheduled",
            cards: []
         },
         {
            id: 5,
            title: "Completed",
            cards: []
         },
         // {
         //     id: 6,
         //     title: "Others",
         //     cards: []
         // }


      ]
   };

    let i = 1
   let allDataToCheckDuplicates = []

    dealsData.forEach((deal) => {

        const dealTrades = deal.UF_CRM_1608388387

      //console.log(`UF_CRM_1608388387: ${dealTrades} typeof: ${typeof(dealTrades)}`)

      if(dealTrades == null || !dealTrades)
         return;

        dealTrades.forEach((trade, index, allDealTrades) => {

           // if (trade == 44 && deal.UF_CRM_1671298235 != 1) // 'Scope Agreed/Ready to Order (R)' should be equal 1
           //    return;
           // if (trade == 44 && deal.UF_CRM_1671298235 != 1) // 'Scope Agreed/Ready to Order (R)' should be equal 1
           //    return;


            const getTradeStatus = (trade, dealData) => {

                if(trade == 44 && deal.UF_CRM_1671298235 == 1) {       //Roofing && 'Scope Agreed/Ready to Order (R)' should be equal 1

                    if( (dealData.UF_CRM_1670943824 == '1763')) //'Ready to Order' и 'Status (R)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670943824 == '1769')) //'Crew Scheduled' и 'Status (R)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670943824 == '1771')) //'Completed' и 'Status (R)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 50 && deal.UF_CRM_1671298263 == 1) {  //Siding

                    if( (dealData.UF_CRM_1670944138 == '1773')) //'Ready to Order' и 'Status (S)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944138 == '1779')) //'Crew Scheduled' и 'Status (S)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944138 == '1781')) //'Completed' и 'Status (S)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 52 && deal.UF_CRM_1671298287 == 1) {  //Windows

                    if( (dealData.UF_CRM_1670944194 == '1783')) //'Ready to Order' и 'Status (W)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944194 == '1789')) //'Crew Scheduled' и 'Status (W)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944194 == '1791')) //'Completed' и 'Status (W)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 319 && deal.UF_CRM_1671298312 == 1) { //Window Wraps

                    if( (dealData.UF_CRM_1670944299 == '1793')) //'Ready to Order' и 'Status (WW)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944299 == '1799')) //'Crew Scheduled' и 'Status (WW)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944299 == '1801')) //'Completed' и 'Status (WW)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 54 && deal.UF_CRM_1671298365 == 1) {  //Gutters
                    if( (dealData.UF_CRM_1670944392 == '1803')) //'Ready to Order' и 'Status (G)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944392 == '1809')) //'Crew Scheduled' и 'Status (G)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944392 == '1811')) //'Completed' и 'Status (G)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 56 && deal.UF_CRM_1671298388 == 1) {  //Fascia
                    if( (dealData.UF_CRM_1670944510 == '1813')) //'Ready to Order' и 'Status (F)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944510 == '1819')) //'Crew Scheduled' и 'Status (F)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944510 == '1821')) //'Completed' и 'Status (F)'
                        return 'Completed'

                   return '';

                }
                else if(trade === 58 && deal.UF_CRM_1671298415 == 1) {  //Deck
                    if( (dealData.UF_CRM_1670944578 == '1823')) //'Ready to Order' и 'Status (D)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944578 == '1829')) //'Crew Scheduled' и 'Status (D)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944578 == '1831')) //'Completed' и 'Status (D)'
                        return 'Completed'

                   return '';
                }
                else if(trade === 60 && deal.UF_CRM_1671298434 == 1) { //Interior
                    if( (dealData.UF_CRM_1670944681 == '1833')) //'Ready to Order' и 'Status (I)'
                        return 'Ready to Order'
                    if( (dealData.UF_CRM_1670944681 == '1839')) //'Crew Scheduled' и 'Status (I)'
                        return 'Crew Scheduled'
                    if( (dealData.UF_CRM_1670944681 == '1841')) //'Completed' и 'Status (I)'
                        return 'Completed'

                   return '';
                }
                else
                    return 'Project Inbox'
                    //return 'Other'


            }

            const curTradeStatus = getTradeStatus(trade, deal)

            const getTradeTypeText = (tradeId) => {
                switch (tradeId) {
                    case 44: return "(R)"
                    case 46: return "(RDG)"
                    case 48: return "(RS)"        //Roof (shed)
                    case 50: return "(S)"
                    case 52: return "(W)"
                    case 54: return "(G)"
                    case 56: return "(F)"
                    case 58: return "(D)"
                    case 60: return "(I)"
                    case 319: return "(WW)"
                    case 62: return "(OTHR)"
                    case 669: return "(OOP)"
                   default: return ""
                }
            }

            const getTradeTypeColor = (tradeId) => {
                switch (tradeId) {
                    case 44: return "bg-red-200"        //Roof
                    case 46: return "bg-pink-200"        //Roof (detached garage)
                    case 48: return "bg-pink-400"        //Roof (shed)
                    case 50: return "bg-blue-200"       //Siding
                    case 52: return "bg-green-200"       //Windows
                    case 54: return "bg-gray-200"       //Gutters
                    case 56: return "bg-purple-200"       //Fascia
                    case 58: return "bg-yellow-200"     //Deck
                    case 60: return "bg-stone-200"      //Interior
                    case 319: return "bg-blue-400"   //Window Wraps
                    case 62: return ""   //Other
                    case 669: return ""   //Out of Pocket
                    default: return ""
                }
            }


            const tradeTypeText = getTradeTypeText(trade)
            const tradeTypeColor = getTradeTypeColor(trade)


              const getTradeStatusDate1 = (tradeId, dealData) => {
                 if(tradeId == 44) {       //Roofing
                    return dealData.UF_CRM_1671277904
                 }
                 else if(tradeId === 50) {  //Siding
                    return dealData.UF_CRM_1671277959
                 }
                 else if(tradeId === 52) {  //Windows
                    return dealData.UF_CRM_1671277985
                 }
                 else if(tradeId === 319) { //Window Wraps
                    return dealData.UF_CRM_1671278032
                 }
                 else if(tradeId === 54) {  //Gutters
                    return dealData.UF_CRM_1671278067
                 }
                 else if(tradeId === 56) {  //Fascia
                    return dealData.UF_CRM_1671278092
                 }
                 else if(tradeId === 58) {  //Deck
                    return dealData.UF_CRM_1671278120
                 }
                 else if(tradeId === 60) { //Interior
                    return dealData.UF_CRM_1671278146
                 }
                 else
                    return '';
              }


            let tmpTrade = {}
            tmpTrade.content = {}
            let copyAllTrades = [...allDealTrades]

            //tmpTrade.id = `${trade}_${deal.ID}_${i++}`
            //tmpTrade.id = `${deal.ID}_${trade}_####_${i++}`
            tmpTrade.id = `${i++}` // это ID для карточек Канбана (не данные)
            tmpTrade.content.id = `${deal.ID}-${trade}`
            tmpTrade.content.tradeId = trade
            copyAllTrades.splice(copyAllTrades.indexOf(trade), 1)
            tmpTrade.content.linkedTrades = copyAllTrades
            tmpTrade.content.linkedDealId = deal.ID
            tmpTrade.content.linkedDealData = deal
            tmpTrade.content.linkedDealTitle = deal.TITLE




            let tradeStatusDateTmp = getTradeStatusDate1(trade, deal)

            if(tradeStatusDateTmp != undefined && tradeStatusDateTmp != '' ) {
              const tradeStatusDateTmp1 = new Date(tradeStatusDateTmp)
              tmpTrade.content.tradeStatusDate = tradeStatusDateTmp1.toLocaleDateString()
            }
            else
              tmpTrade.content.tradeStatusDate = ''

            if(allDataToCheckDuplicates.indexOf(tmpTrade.content.id) != -1)
               return;
            else
               allDataToCheckDuplicates.push(tmpTrade.content.id)


            switch (curTradeStatus) {
               case 'Project Inbox':
                  tradesCardData.columns[0].cards.push(tmpTrade)
                  break
                case 'Ready to Order':
                    tradesCardData.columns[1].cards.push(tmpTrade)
                    break
                case 'Crew Scheduled':
                    tradesCardData.columns[4].cards.push(tmpTrade)
                    break
                case 'Completed':
                    tradesCardData.columns[5].cards.push(tmpTrade)
                    break
                // default:
                //     tradesCardData.columns[5].cards.push(tmpTrade)
                //     break
            }

            //tradeData.push(tmpTrade)

        })

        //tradeData.push(deal)
    })

// console.log('tradesCardData')
// console.log(tradesCardData)

   // tradesCardData = dealsData.filter( (item,index) => {
   //    return (tradesCardData.indexOf(item) == index)
   // })


   return tradesCardData
}

export const getSubContractors = async () => {

  //console.log('=== getting subs ====')

  //let url = `/rest/4/bcn7t016d5tuehkp/user.search.json?select[]=id&select[]=*&select[]=UF_*&filter[USER_TYPE]=extranet`
  //let url = `/rest/4/bcn7t016d5tuehkp/user.search.json?select[]=id&select[]=*&select[]=UF_*&filter[UF_DEPARTMENT_NAME]=Subs`
  let url = `/rest/4/bcn7t016d5tuehkp/user.search.json?select[]=id&select[]=*&select[]=UF_*&filter[UF_DEPARTMENT_NAME]=Subs&&filter[ACTIVE]=false`
  const response = await github.get(url)

  return response.data.result.sort((a,b) => {

    var nameA = a.NAME.toUpperCase(); // ignore upper and lowercase
    var nameB = b.NAME.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  })

}

export const getPhotos = async (dialId) => {


  //task.commentitem.getlist
  //[ATTACHED_OBJECTS]
  //? disk.attachedObject.get
  //[DOWNLOAD_URL]

  //console.log('=== getting PHOTOS ====')

  let url = `/rest/4/bcn7t016d5tuehkp/tasks.task.list?select[]=*&filter[%TITLE]=ProdChkList&filter[UF_CRM_TASK]=D_${dialId}`
  const responseTask = await github.get(url)

  //console.log('======= taskInfo:')
  let taskId = ''
  if(responseTask.data.total > 0) {

    taskId = responseTask.data.result.tasks[0].id
    //console.log(responseTask.data.result.tasks[0].id)

  }
  else {
    return []
  }




  url = `/rest/4/bcn7t016d5tuehkp/task.commentitem.getlist?TASKID=${taskId}`
  const response = await github.get(url)

  // console.log('======= comments Info:')
  // console.log(response)


//  console.log('======= attachments IDs:')

  let fileIDs = []
  response.data.result.forEach((fileInfo) => {

    if(fileInfo.ATTACHED_OBJECTS) {

      const objects = Object.entries(fileInfo.ATTACHED_OBJECTS)

      // console.log("objects:")
      // console.log(objects)

      if (objects.length > 0)
        objects.forEach((data) => {
          fileIDs.push(data[0])
        })

    }
  })

  //console.log(fileIDs)



  //console.log('======= test attachment info:')

   const delay = (time) => {
      return new Promise(resolve => setTimeout(resolve, time));
   }

  let i = 1
  let photoData = []
  for (const fileId of fileIDs) {

     if (i % 30 === 0) {

        delay(5000).then(() => console.log('>----------> Continue after 5 sec. pause >---------->'));

        i = 1
     }
     else
        i++

    url = `/rest/4/bcn7t016d5tuehkp/disk.attachedObject.get?id=${fileId}`
    const responseAttachment = await github.get(url)

    if(responseAttachment.data.result.NAME.toLowerCase().indexOf(".pdf") !== -1)
      continue

//console.log("responseAttachment:")
//console.log(responseAttachment)

    let attachemtnInfo = {
      fileId: fileId,
      fileName: responseAttachment.data.result.NAME,
      url: responseAttachment.data.result.DOWNLOAD_URL

    }

    photoData.push(attachemtnInfo)

  }

   console.log('=== Finished receiving photos URLs')


  // console.log("photoData from the ECActions: +++++++++++++++")
  // console.log(photoData)

  // let testArray = []
  // testArray.push({id: 1})
  // testArray.push({id: 2})

  return photoData
  //return testArray
}

export const getCalendarEvents = async () => {

  /*
      const calendarEvents = [
        { title: 'event 1', date: '2021-12-10' },
        { title: 'event 2', date: '2021-12-11' }
      ]
    */
  const calendarEvents = []
  let url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=11&section=37&from=2021-12-01`
  let response = await github.get(url)


// //Acme
//   let url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=11&section=37&from=2021-12-01`
//   let response = await github.get(url)
//
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//          backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Contac Construction LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=15&section=85&from=2021-12-01`
//   response = await github.get(url)
//
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//          backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //E & S Quality Services LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=17&section=71&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//          backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Exterior Home Renovation LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=23&section=91&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //FS Roofing Construction LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=27&section=93&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Grupo Carso
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=59&section=251&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //jreyexteriors
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=63&section=273&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Luna Roofing
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=35&section=49&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Lund Construction LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=37&section=79&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Mercado Construction LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=39&section=51&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Morelos Roofing LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=41&section=81&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Shakopee Construction LLC
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=45&section=83&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//           backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
// //Wright-Way Seamless Inc
//   url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=53&section=97&from=2021-12-01`
//   response = await github.get(url)
//
//   response.data.result.forEach( (event) => {
//     let fromDate = new Date(event.DATE_FROM)
//     fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//     calendarEvents.push(
//        {
//          title: event.NAME,
//          date: fromDate.toISOString().split('T')[0],
//          id: event.ID,
//          backgroundColor: event.COLOR
//          //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//        }
//     )
//   })
//
//
// // //CameroAble (его больше нет)
// //    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=67&section=307&from=2021-12-01`
// //    response = await github.get(url)
// //
// //    response.data.result.forEach( (event) => {
// //       let fromDate = new Date(event.DATE_FROM)
// //       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
// //
// //       calendarEvents.push(
// //          {
// //             title: event.NAME,
// //             date: fromDate.toISOString().split('T')[0],
// //             id: event.ID,
// //             backgroundColor: event.COLOR
// //             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
// //          }
// //       )
// //    })
//
// //CamaroAble1
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=95&section=511&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// //MBJMConstruction
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=69&section=315&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// //akaconstruction
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=73&section=343&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// //Primeelementconstruction
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=77&section=371&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
//
//
// // Opendoorrestoration
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=79&section=375&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// // INDROOFING
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=83&section=381&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// // Allroofsandsiding
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=89&section=449&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// // lsgconstruction
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=87&section=411&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })
//
// // mapexteriorllc
//    url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=91&section=455&from=2021-12-01`
//    response = await github.get(url)
//
//    response.data.result.forEach( (event) => {
//       let fromDate = new Date(event.DATE_FROM)
//       fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
//
//       calendarEvents.push(
//          {
//             title: event.NAME,
//             date: fromDate.toISOString().split('T')[0],
//             id: event.ID,
//             backgroundColor: event.COLOR
//             //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
//          }
//       )
//    })


// *********************************************************************************************************************
// Получим все экстранет-группы

   const webhookUrl = 'https://estateclaim.bitrix24.com/rest/4/8hwtage786darjve/';
   const method = 'sonet_group.get';
   const params = {
      ORDER: { DATE_CREATE: 'ASC' }, // Пример сортировки, можно изменить по вашему усмотрению
      FILTER: { IS_EXTRANET: 'Y' } // Фильтруем, чтобы получить только extranet-группы
   };

   response = await github.post(`${webhookUrl}${method}`, params)
//console.log('Список extranet-групп:', response.data);



   for(let group of response.data.result) {

   //response.data.result.forEach( (group) => {
      let groupId = group.ID;

      const webhookUrl = 'https://estateclaim.bitrix24.com/rest/4/8hwtage786darjve/';
      const method = 'calendar.section.get';
      const params1 = {
            'type': 'group', // Тип календаря - группа
            'ownerId': groupId, // ID группы
      };

      response = await github.post(`${webhookUrl}${method}`, params1)

//      console.log('Данные календаря:', response.data);


      if(response.data.result.length > 0) {
         url = `/rest/4/8hwtage786darjve/calendar.event.get.json?type=group&ownerId=${groupId}&section=${response.data.result[0].ID}&from=2023-08-01`
         let response1 = await github.get(url)

//console.log('EVENTы календаря:', response1.data);

         for(let event of response1.data.result) {
         //response1.data.result.forEach( (event) => {
            let fromDate = new Date(event.DATE_FROM)
            fromDate.setTime(fromDate.getTime() + (9*60*60*1000))

            calendarEvents.push(
               {
                  title: event.NAME,
                  date: fromDate.toISOString().split('T')[0],
                  id: event.ID,
                  backgroundColor: event.COLOR
                  //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
               }
            )
         }
      }




      // let event = response.data[0];
      // let fromDate = new Date(event.DATE_FROM)
      // fromDate.setTime(fromDate.getTime() + (9*60*60*1000))
      // calendarEvents1.push(
      //    {
      //       title: event.NAME,
      //       date: fromDate.toISOString().split('T')[0],
      //       id: event.ID,
      //       backgroundColor: event.COLOR
      //       //date: `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDay()}`
      //    }
      // )




   }












//  console.log('CALENDAR Events:')
//   console.log(response.data)
//   console.log(calendarEvents)


  return calendarEvents


}

export const getCalendarEvent = async (eventId, eventTitle, event) => {

  let dealId = ""
  let tradeType = []

  let matches = eventTitle.match(/\[(.*?)\]/);
  if (matches) {
    dealId = matches[1];
  }

  //matches = eventTitle.match(/\((.*?)+\)/);
  //matches = eventTitle.match(/\(([^\)]*)\)/);

   //matches = eventTitle.match(/\((.*?)\)/gm);

   matches = eventTitle.match(/\(([A-Z]+)\)/gm); //working

   console.log('MATCHES:')
   console.log(matches)

   matches.forEach((match) => {
      let temp = match.replace("(", "")
      temp = temp.replace(")", "")

      tradeType.push(temp)
   })

   console.log("tradeType")
   console.log(tradeType)



  // console.log(`delId: ${dealId}`)
  // console.log(`tradeType: ${tradeType}`)
  // console.log(`eventId: ${eventId}`)


  let url = `/rest/4/8hwtage786darjve/calendar.event.getbyid.json?id=${eventId}`
  const response = await github.get(url)


   url = `/rest/4/8hwtage786darjve/crm.deal.get.json?id=${dealId}`
   const responseDealData = await github.get(url)



  console.log("Event data:")
  console.log(response)

   console.log("Deal data:")
   console.log(responseDealData)




  //const subId = response.data.result.ATTENDEES_CODES[0].replace('U', '')
  const subId = response.data.result.ATTENDEE_LIST[0].id

  // console.log("subId:")
  // console.log(subId)
  //
  // console.log("Event data:")
  // console.log(event)

  return {
    dealId: dealId,
    tradeType: tradeType,
    subId: subId,
    date: event.startStr,
    eventId: eventId,

    dealData:  responseDealData.data.result
  }

}

export const updateTradeStatusFromKanban = async (
   dealId,
   dealTradeField,
   tradeValue,
   dealStatusCompletedField,
   dealStatusCompletedValue) => {

    let url = ''
    //url = `/rest/4/bcn7t016d5tuehkp/crm.deal.list.json?select[]=id&select[]=title&select[]=UF_*&order[ID]=DESC`


   console.log('Будем обновлять сделку')
   console.log(`dealId: ${dealId} dealTradeFiled: ${dealTradeField} tradeValue: ${tradeValue}`)

   url = `/rest/4/bcn7t016d5tuehkp/crm.deal.update.json?id=${dealId}&fields[${dealTradeField}]=${tradeValue}&fields[${dealStatusCompletedField}]=${dealStatusCompletedValue}`
    let response = await github.get(url)

   console.log('Res')
   console.log(response)



    return true
}



// Get user and repos
export const getUserAndRepos = async (login) => {
  const [user, repos] = await Promise.all([
    github.get(`/users/${login}`),
    github.get(`/users/${login}/repos`),
  ])

  return { user: user.data, repos: repos.data }
}
