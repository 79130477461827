import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



import DealResults from '../components/deals/DealResults'
import DealSearch from '../components/deals/DealSearch'
import FullCalendar/*, { formatDate }*/ from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin/*, { Draggable }*/ from '@fullcalendar/interaction'
import iCalendarPlugin from '@fullcalendar/icalendar'
import {useContext, useEffect} from "react";
//import {searchUsers} from "../context/deal-assignments/ECActions";
import Button from '@mui/material/Button';
import NewBuildDateDlg from "../components/deals/NewBuildDateDlg";
import UpdateBuildDateDlg from "../components/deals/UpdateBuildDateDlg"
import {useState} from 'react';
import ECContext from "../context/deal-assignments/ECContext";
import {
   getCalendarEvent,
   getCalendarEvents,
   getSubContractors,
   searchUsers
} from "../context/deal-assignments/ECActions";

import KanbanTrades from "../components/kanban/Kanban";
//import {getPhotos} from "../context/deal-assignments/ECActions";

import {Routes, Route, useNavigate} from 'react-router-dom';
import Box from "@mui/material/Box";




function Home() {

    //const [showDialog, setShowDialog] = useState(false);

    const { showBuildDateDialog, dispatch, users, calendarEvents, checkedTradeIDs } = useContext(ECContext)



    const eventClickHandler = async (info) => {
        var eventObj = info.event;

        if (eventObj.url) {
            // alert(
            //     'Clicked ' + eventObj.title + '.\n' +
            //     'Will open ' + eventObj.url + ' in a new tab'
            // );
            //
            // window.open(eventObj.url);
            //
            // info.jsEvent.preventDefault(); // prevents browser from following link in current tab.
        } else {

           //alert('Clicked ' + eventObj.title);

// console.log("info:")
// console.log(info)

           const calendarEventTemp = await getCalendarEvent(eventObj.id, eventObj.title, eventObj)
           dispatch({ type: 'GET_CALENDAR_EVENT', payload: calendarEventTemp })


           // const droppedDealId = info.draggedEl.id.replace('m_', '')
           // const curUser = users.filter((user) => {
           //    return user.ID === droppedDealId
           // })[0]
           // const dealData = {
           //    ...curUser,
           //    dealId: info.draggedEl.id.replace('m_', ''),
           //    //dealTitle: dropInfo.draggedEl.innerText.substr(0, dropInfo.draggedEl.innerText.indexOf('\n')),
           //    buildDate: info.dateStr
           // }
           // dispatch({ type: 'SET_DEAL_DATA', payload: dealData })


           dispatch({ type: 'SET_SHOW_UPDATE_DIALOG' })

        }
    }

    const myDropEventHandler = async (dropInfo) => {
        // console.log(dropInfo)
        // alert('Ku-ku!')
        //setShowDialog(true) //

       //const droppedDealId = dropInfo.draggedEl.id.replace('m_', '')
       const droppedDealId = dropInfo.draggedEl.id.split('-', 1)[0]
       const droppedTradeIDs = [dropInfo.draggedEl.id.split('-', 2)[1], ...checkedTradeIDs]

// console.log("Sending this tradIDs to Dialog:")
// console.log(droppedTradeIDs)


       // const photos = await getPhotos(droppedDealId)
       // dispatch({ type: 'GET_PHOTOS', payload: photos })





       //const { users } = useContext(ECContext)

       const curUser = users.filter((user) => {
          return user.ID === droppedDealId
       })[0]

 // console.log('====================')
 // console.log(curUser)

        const dealData = {
            ...curUser,
            dealId: droppedDealId,
            tradeId: droppedTradeIDs,
            //dealTitle: dropInfo.draggedEl.innerText.substr(0, dropInfo.draggedEl.innerText.indexOf('\n')),
            buildDate: dropInfo.dateStr
        }
        dispatch({ type: 'SET_DEAL_DATA', payload: dealData })

        dispatch({ type: 'SET_SHOW_DIALOG' })



    }



   const changeDayDropEventHandler = async (dropInfo) => {
      //console.log(dropInfo)
      //alert('Ku-ku! Change day drop event')

      let eventObj = dropInfo.event;

      const calendarEventTemp = await getCalendarEvent(eventObj.id, eventObj.title, eventObj)
      dispatch({ type: 'GET_CALENDAR_EVENT', payload: calendarEventTemp })
      dispatch({ type: 'SET_SHOW_UPDATE_DIALOG' })

      dropInfo.revert();
   }

   useEffect(async () => {


      //dispatch({ type: 'SET_CALENDAR_LOADING' })
      const calendarEvents = await getCalendarEvents()
      dispatch({ type: 'GET_CALENDAR_EVENTS', payload: calendarEvents })


      // var calendar = new Calendar(calendarEl, {
      //    plugins: [dayGridPlugin, iCalendarPlugin],
      //    events: {
      //       url: 'https://estateclaim.bitrix24.com/company/personal/user/4/calendar/?action=export&type=group&owner=9&ncc=1&user=4&sec_id=33&sign=c60ea3e1a3cad243990c77c2f92215f3&bx_hit_hash=nx8q21m914obyc6rrg9fzw78fm5a717d',
      //       format: 'ics'
      //    }
      // })

   }, []);


    //useEffect(() => {

        //var containerEl = document.getElementById('deals')

        //var draggableEl1 = document.getElementById('draggable-el55');

        //let draggableEl = document.getElementById('draggable-el2');

        //console.log(containerEl)


        // new Draggable(containerEl, {
        //     itemSelector: '.card',
        //     eventData: function(eventEl) {
        //         return {
        //             title: 'test'//eventEl.innerText
        //         };
        //     }
        // });

       //new Draggable(containerEl);

        //new Draggable(draggableEl1);


//debugger
//         new Draggable(draggableEl1, {
//             itemSelector: '#draggable-el55',
//             eventData: {
//                 title: 'my event',
//                 duration: '02:00'
//             }
//         });

    //}, []);

    const [checked, setChecked] = React.useState(true);
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        navigate('./kanban/')
    };

   // console.log('locations:');
   // console.log(window.location.ancestorOrigins[0]);
   // console.log(window.parent.location);

   // console.log('window.location.ancestorOrigins[0]:')
   // console.log(window.location.ancestorOrigins[0])

   //if ( window.location !== window.parent.location )
   //if (window.location.ancestorOrigins[0] == 'https://estateclaim.bitrix24.com')

   if (window.location.ancestorOrigins[0] == 'https://estateclaim.bitrix24.com')
//   if (true)
   {
      console.log('passing');
      return (
         <>

            <NewBuildDateDlg showDialog={showBuildDateDialog} />
            <UpdateBuildDateDlg />


            <div className="grid grid-cols-12 gap-4">

               <div className="col-span-3">


                  <aside className="max-h-screen overflow-y-scroll">

                     <DealSearch />
                     <DealResults />


                     <FormGroup className="col-span-1 ">
                        {/*<FormControlLabel control={<Switch defaultChecked />} label="Label" />*/}
                        {/*<FormControlLabel control={<Switch onChange={handleChange} />} label="Calendar / Kanban" />*/}
                     </FormGroup>



                  </aside>
               </div>


               <main className="col-span-9">

                  {/*                <KanbanTrades />*/}

                  <FullCalendar
                     plugins={[ interactionPlugin, dayGridPlugin ]}
                     timeZone="EST"
                     initialView="dayGridMonth"
                     selectable={true}

                     editable={true}
                     dayMaxEvents={true}
                     //events={'https://fullcalendar.io/demo-events.json?overload-day'}
                     events={calendarEvents}
                     eventClick={eventClickHandler}
                     droppable={true}
                     drop={myDropEventHandler}

                     eventDrop={changeDayDropEventHandler}

                  />
               </main>
            </div>

            <div className="grid grid-cols-2 gap-4">
               <div className="max-w-sm">
               </div>
               <div className="min-w-full">

               </div>
            </div>







         </>
      )
   }
   else {
      return <></>;
   }



}

export default Home


