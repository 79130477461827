import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import {useContext, useEffect} from "react";

import ECContext from "../context/deal-assignments/ECContext";
import {
   getCalendarEvent,
   getCalendarEvents,
   getSubContractors,
   searchUsers
} from "../context/deal-assignments/ECActions";

import KanbanTrades from "../components/kanban/Kanban";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {Grid} from "@mui/material";
import KanbanSearch from "../components/kanban/KanbanSearch";



function KanbanPage() {

    //const [showDialog, setShowDialog] = useState(false);

    const { showBuildDateDialog, dispatch, users, calendarEvents } = useContext(ECContext)

    useEffect(async () => {

    }, []);




    const [checked, setChecked] = React.useState(true);
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        navigate('/')
    };




  return (
       <Grid container spacing={0}>
          <Grid item xs={9} >
             <FormGroup>
                {/*<FormControlLabel control={<Switch defaultChecked />} label="Label" />*/}
                {/*<FormControlLabel control={<Switch onChange={handleChange} />} label="Calendar / Kanban" />*/}
                <FormControlLabel control={<Switch onChange={handleChange} defaultChecked/>} label="Back to Calendar" />

             </FormGroup>
          </Grid>
          <Grid item xs={3} >
             <KanbanSearch/>
          </Grid>
          <Grid item xs={12}>
             <KanbanTrades />
          </Grid>
       </Grid>

  )
}

export default KanbanPage


