import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {grey} from "@mui/material/colors";
import ECContext from "../../context/deal-assignments/ECContext";




function DealItem( {prop} ) {

   // console.log("party assigned: ")
   // console.log(deal.showOnlyPartlyAssigned)


   // console.log("income PROP:")
   // console.log(prop)

   let deal = prop.trades.trade.linkedDealData


   const showOnlyPartlyAssigned = false //prop.showOnlyPartlyAssigned.showOnlyPartlyAssigned

   // console.log("showOnlyPartlyAssigned:")
   // console.log(showOnlyPartlyAssigned)

   // const _trades = []
   //
   // const crewRBuildDate = deal.UF_CRM_1610705339
   // const crewSBuildDate = deal.UF_CRM_1610877006
   // const crewWBuildDate = deal.UF_CRM_1610877523
   // const crewGBuildDate = deal.UF_CRM_1610877363
   // const crewFBuildDate = deal.UF_CRM_1610877224
   // const crewDBuildDate = deal.UF_CRM_1610877813
   // const crewIBuildDate = deal.UF_CRM_1610877927
   // const crewWWBuildDate = deal.UF_CRM_1611841827

/*   const scopeOfWork = Object.values(deal.UF_CRM_1608388387)

   let tempBuildDate = ""
   let tempTradeType = ""

   if(crewRBuildDate !== "") {
      tempBuildDate = crewRBuildDate
      tempTradeType = "Roof"

      const trade = {type: "Roof", buildDate: convertDate(crewRBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(44) !== -1) {
      const trade = {type: "Roof", buildDate: ""}
      _trades.push(trade)
   }


   if(crewSBuildDate !== "") {
      tempBuildDate = crewSBuildDate
      tempTradeType = "Siding"

      const trade = {type: "Siding", buildDate: convertDate(crewSBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(50) !== -1) {
      const trade = {type: "Siding", buildDate: ""}
      _trades.push(trade)
   }



   if(crewWBuildDate !== "") {
      tempBuildDate = crewWBuildDate
      tempTradeType = "Windows"

      const trade = {type: "Windows", buildDate: convertDate(crewWBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(52) !== -1) {
      const trade = {type: "Windows", buildDate: ""}
      _trades.push(trade)
   }



   if(crewGBuildDate !== "") {
      tempBuildDate = crewGBuildDate
      tempTradeType = "Gutters"

      const trade = {type: "Gutters", buildDate: convertDate(crewGBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(54) !== -1) {
      const trade = {type: "Gutters", buildDate: ""}
      _trades.push(trade)
   }



   if(crewFBuildDate !== "") {
      tempBuildDate = crewFBuildDate
      tempTradeType = "Fascia"

      const trade = {type: "Fascia", buildDate: convertDate(crewFBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(56) !== -1) {
      const trade = {type: "Fascia", buildDate: ""}
      _trades.push(trade)
   }


   if(crewDBuildDate !== "") {
      tempBuildDate = crewDBuildDate
      tempTradeType = "Deck"

      const trade = {type: "Deck", buildDate: convertDate(crewDBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(58) !== -1) {
      const trade = {type: "Deck", buildDate: ""}
      _trades.push(trade)
   }


   if(crewIBuildDate !== "") {
      tempBuildDate = crewIBuildDate
      tempTradeType = "Interior"

      const trade = {type: "Interior", buildDate: convertDate(crewIBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(60) !== -1) {
      const trade = {type: "Interior", buildDate: ""}
      _trades.push(trade)
   }


   if(crewWWBuildDate !== "") {
      tempBuildDate = crewWWBuildDate
      tempTradeType = "Window Wraps"

      const trade = {type: "Window Wraps", buildDate: convertDate(crewWWBuildDate)}
      _trades.push(trade)
   }
   else if(scopeOfWork.indexOf(319) !== -1) {
      const trade = {type: "Window Wraps", buildDate: ""}
      _trades.push(trade)
   }*/



   function convertDate(tempBuildDate) {

      if(tempBuildDate !== "") {


         //let x = "2011-01-28T19:30:00EST"
         //let x =   "2021-11-16T09:00:00+03:00"
         let x = tempBuildDate

         let MM = {Jan:"January", Feb:"February", Mar:"March", Apr:"April", May:"May", Jun:"June", Jul:"July", Aug:"August", Sep:"September", Oct:"October", Nov:"November", Dec:"December"}

         let xx = String(new Date(x)).replace(
            /\w{3} (\w{3}) (\d{2}) (\d{4}) (\d{2}):(\d{2}):[^(]+\(([A-Z]{3})\)/,
            function($0,$1,$2,$3,$4,$5,$6){
               return MM[$1]+" "+$2+", "+$3+" - "+$4%12+":"+$5+(+$4>12?"PM":"AM")+" "+$6
            }
         )
         // console.log("xx:")
         // console.log(xx)

         const formattedDate = new Date(xx)
         // console.log("new date:")
         // console.log(formattedDate.getFullYear())

         // console.log('Title:')
         // console.log(deal.TITLE)

         //tempBuildDate = `${formattedDate.getMonth()}/${formattedDate.getDay()}/${formattedDate.getFullYear()}`
         tempBuildDate = formattedDate.toDateString()

         return tempBuildDate;


         // console.log('Filan data')
         // console.log(tempBuildDate)

      }
   }





   // const [buildDate, setBuildDate] = useState(tempBuildDate);
   // const [tradeType, settradeType] = useState(tempTradeType);

   //const [trades, setTrades] = useState(_trades);


    useEffect(() => {

        let containerEl = document.getElementById(`${prop.trades.trade.id}`)

        if (containerEl != null) {

            new Draggable(containerEl, {
                // eventData: function(eventEl) {
                //     return {
                //         //title: deal.TITLE//eventEl.innerText
                //         title: ""
                //     };
                // }
            });

        }
    }, []);



   //const tradesWithEmptyBuildDates = trades.filter((trade) => trade.buildDate === "")
   //const totalUnasignedTrades =  Object.keys(tradesWithEmptyBuildDates).length

   //let needToShow = false

   // if (showOnlyPartlyAssigned === true && totalUnasignedTrades > 0)
   //    needToShow = true
   // else if (showOnlyPartlyAssigned === false)
   //    needToShow = true


    const getTradeTypeText = (tradeId) => {
        switch (tradeId) {
            case 44: return "(R)"
            case 46: return "(RDG)"
            case 48: return "(RS)"        //Roof (shed)
            case 50: return "(S)"
            case 52: return "(W)"
            case 54: return "(G)"
            case 56: return "(F)"
            case 58: return "(D)"
            case 60: return "(I)"
            case 319: return "(WW)"
            case 62: return "(OTHR)"
            case 669: return "(OOP)"
        }
    }

    const getTradeTypeColor = (tradeId) => {
        switch (tradeId) {
            case 44: return "bg-red-200"        //Roof
            case 46: return "bg-red-200"        //Roof (detached garage)
            case 48: return "bg-red-200"        //Roof (shed)
            // case 46: return "bg-pink-200"        //Roof (detached garage)
            // case 48: return "bg-pink-400"        //Roof (shed)
            case 50: return "bg-blue-200"       //Siding
            case 52: return "bg-green-200"       //Windows
            case 54: return "bg-gray-200"       //Gutters
            case 56: return "bg-purple-200"       //Fascia
            case 58: return "bg-yellow-200"     //Deck
            case 60: return "bg-stone-200"      //Interior
            case 319: return "bg-blue-400"   //Window Wraps
            case 62: return ""   //Other
            case 669: return ""   //Out of Pocket
            default: return ""
        }
    }

    const makeCompletedBold = (tradeId) => {

       //console.log('tradeId:' + tradeId + 'value:' + prop.trades.trade.linkedDealData.UF_CRM_1670943824)

       if(tradeId == 44 && prop.trades.trade.linkedDealData.UF_CRM_1670943824 == 1771) //Roof
          return ' font-semibold '
       if(tradeId == 46 && prop.trades.trade.linkedDealData.UF_CRM_1670943824 == 1771)
          return ' font-semibold '
       if(tradeId == 48 && prop.trades.trade.linkedDealData.UF_CRM_1670943824 == 1771)
          return ' font-semibold '
       if(tradeId == 50  && prop.trades.trade.linkedDealData.UF_CRM_1670944138 == 1781) //Siding
          return ' font-semibold '
       if(tradeId == 52  && prop.trades.trade.linkedDealData.UF_CRM_1670944194 == 1791) //Windows
          return ' font-semibold '
       if(tradeId == 54  && prop.trades.trade.linkedDealData.UF_CRM_1670944392 == 1811) //Gutters
          return ' font-semibold '
       if(tradeId == 56  && prop.trades.trade.linkedDealData.UF_CRM_1670944510 == 1821) //Fascia
          return ' font-semibold '
       if(tradeId == 58  && prop.trades.trade.linkedDealData.UF_CRM_1670944578 == 1831) //Deck
          return ' font-semibold '
       if(tradeId == 60  && prop.trades.trade.linkedDealData.UF_CRM_1670944681 == 1841) //Interior
          return ' font-semibold '
       if(tradeId == 319  && prop.trades.trade.linkedDealData.UF_CRM_1670944299 == 1801) //Window Wraps
          return ' font-semibold '

       return ''
   }

   const [checked, setChecked] = useState(true);
   const chkBoxlabel = { inputProps: { 'tmp': 1 } };

   const { checkedTradeIDs, dispatch } = useContext(ECContext)

   const handleChangeCheckTrade = (event) => {
      setChecked(event.target.checked);

      let newCheckedTradeIDs = [...checkedTradeIDs];

      if(event.target.checked === true) {
         if(newCheckedTradeIDs.indexOf(event.target.id) == -1)
            newCheckedTradeIDs.push(event.target.id)
      }
      else {// unchecking
         if(newCheckedTradeIDs.indexOf(event.target.id) != -1)
            newCheckedTradeIDs.splice(newCheckedTradeIDs.indexOf(event.target.id), 1)
      }


      dispatch({ type: 'UPDATE_CHECK_TRADE_IDS', payload: newCheckedTradeIDs })

   };

    const tradeTypeText = getTradeTypeText(prop.trades.trade.tradeId)
    const tradeTypeColor = getTradeTypeColor(prop.trades.trade.tradeId)


    // console.log('====== ID for card ')
    // console.log(prop.trades.trade.id)



    return (

    <div id={`${prop.trades.trade.id}`} draggable={true} className='card shadow-md compact side bg-base-100' >
      <div className='flex-row items-center space-x-4 card-body '>


         <div>

         <Grid container spacing={0}>
            <Grid item xs={12}>
               <a href={`https://estateclaim.bitrix24.com/crm/deal/details/${prop.trades.trade.linkedDealId}/`} target="_blank">
               <h2 className={'text-sm rounded ' + tradeTypeColor}>{tradeTypeText + ' ' + prop.trades.trade.linkedDealTitle}</h2></a>
            </Grid>
            <Grid item xs={11}>
{/*               <div className='mt-1 font-thin text-7 '>*/}

                  <div className='flex flex-row mt-1 font-thin space-x-1 '>

                     Related: &nbsp;
                     {prop.trades.trade.linkedTrades.map((tradeId) => (
                        <div className={'rounded-md   ' + getTradeTypeColor(tradeId) + makeCompletedBold(tradeId)} >
                           {getTradeTypeText(tradeId)}
                        </div>
                     ))}

                  </div>



{/*               </div>*/}
            </Grid>

            <Grid item xs={1} spacing={0}>
               <Box >
                  <Checkbox
                     id = {`${prop.trades.trade.id.split('-', 2)[1]}`}
                     {...chkBoxlabel}
                     size='small'
                     sx={{ pl: 0, ml: 0 }}

                     checked={checked[1]}
                     onChange={handleChangeCheckTrade}

                  />
               </Box>
            </Grid>

            <Grid item xs={6} spacing={0} >
               <Box>
                  {`[${prop.trades.trade.tradeStatus}]`}
               </Box>
            </Grid>
            <Grid item xs={6} spacing={0}>
               <Box>
                  {`${prop.trades.trade.tradeStatusDate}`}
               </Box>
            </Grid>


         </Grid>





         </div>

{/*
        <div>
            <a href={`https://estateclaim.bitrix24.com/crm/deal/details/${deal.ID}/`} target="_blank">
               <h2 className='card-title text-sm'>{deal.TITLE}</h2>
            </a>


           {showOnlyPartlyAssigned === true && (<div>TRUE</div>)}
           partly assigned: <strong>{showOnlyPartlyAssigned}</strong>



            {trades.map((trade) => (

               trade.buildDate === ""
                  ? <div className=''>{trade.type}</div>
                  : <div className='bg-green-200'>{trade.buildDate} {tradeType !== "" && <i>, {trade.type}</i>}</div>

            ))}


        </div>
*/}

      </div>
    </div>

  )
}

DealItem.propTypes = {
  //deal: PropTypes.object.isRequired,
}

export default DealItem
