import {useContext, useEffect, useState} from "react";
import ECContext from "../../context/deal-assignments/ECContext";
import {getTradesForKanban, updateTradeStatusFromKanban, searchUsers} from "../../context/deal-assignments/ECActions";

import ReactDOM from "react-dom";
import Board, { moveCard } from "@lourenci/react-kanban";
//import "@lourenci/react-kanban/dist/styles.css";
import './styles.css'
import Spinner from "../layout/Spinner";

import DealItem from  '../deals/DealItem'
// https://github.com/asseinfo/react-kanban


function KanbanSearch() {
    const [text, setText] = useState('')
    const { tradesForKanban, loading, dispatch } = useContext(ECContext)


    useEffect(async () => {


    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault()

        // if (text === '') {
        //   setAlert('Please enter something', 'error')
        // } else {

        dispatch({ type: 'SET_LOADING' })
        const kanbanTrades = await getTradesForKanban(text)
        dispatch({ type: 'SET_TRADES_FOR_KANBAN', payload: kanbanTrades })

    }

    const handleChange = (e) => setText(e.target.value)



    return (

       <form onSubmit={handleSubmit}>
           <div className='form-control'>
               <div className='relative'>
                   <input
                      type='text'
                      className='w-full pr-20 bg-gray-200 input text-black'
                      placeholder='Search'
                      value={text}
                      onChange={handleChange}
                   />

                   <button
                      type='submit'
                      className='absolute top-0 right-0 rounded-l-none w-30 btn'
                   >
                       Filter
                   </button>
               </div>
           </div>
       </form>

    );




}

KanbanSearch.propTypes = {
  //deal: PropTypes.object.isRequired,
}

export default KanbanSearch
