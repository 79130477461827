import { useState, useContext, useEffect } from 'react'
import ECContext from '../../context/deal-assignments/ECContext'
import AlertContext from '../../context/alert/AlertContext'
import { searchUsers } from '../../context/deal-assignments/ECActions'
import { getSubContractors } from '../../context/deal-assignments/ECActions'

import Checkbox from '@mui/material/Checkbox';
import Switch from "@mui/material/Switch";
import * as React from "react";
import {useNavigate} from "react-router-dom";

function DealSearch() {
  const [text, setText] = useState('')
  const [stage, setStage] = useState('1853') //'Ready to Order' trade stage
  const [onlyPartlyAssigned, setonlyPartlyAssigned] = useState(false)


  const { users, dispatch } = useContext(ECContext)
  const { setAlert } = useContext(AlertContext)

  const navigate = useNavigate();

  const handleChange = (e) => setText(e.target.value)

  const handleChangeStage = async (e) => {
    setStage(e.target.value)

    dispatch({ type: 'SET_FILTER_STAGE', payload: e.target.value })

    dispatch({ type: 'SET_LOADING' })
    const users = await searchUsers(text, e.target.value)
    dispatch({ type: 'GET_USERS', payload: users })

  }

  useEffect(async () => {
    dispatch({ type: 'SET_LOADING' })

    const users = await searchUsers(text, stage)
    dispatch({ type: 'GET_USERS', payload: users })

    const subs = await getSubContractors()
    dispatch({ type: 'GET_SUBS', payload: subs })
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault()

    // if (text === '') {
    //   setAlert('Please enter something', 'error')
    // } else {
      dispatch({ type: 'SET_LOADING' })
      const users = await searchUsers(text, stage, onlyPartlyAssigned)
      dispatch({ type: 'GET_USERS', payload: users })

      //setText('')
//    }
  }

  const handleChangeOnlyPartlyAssigned = async (event) => {
    setonlyPartlyAssigned([event.target.checked, event.target.checked]);


    // console.log("setting up Partly assinged variable with: ")
    // console.log(event.target.checked)
    dispatch({ type: 'SET_PARTLY_ASSIGNED', payload: event.target.checked })


    dispatch({ type: 'SET_LOADING' })
    const users = await searchUsers(text, stage, event.target.checked)
    dispatch({ type: 'GET_USERS', payload: users })


  };

  const handleChangeView = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setChecked(event.target.checked);

    navigate('/kanban/')
  };



  return (
    <div className='grid grid-cols-1 mb-1 gap-1 z-10 fixed top-0 bg-gray-50 '>
      <div>
        <form onSubmit={handleSubmit}>
          <div className='form-control'>
            <div className='relative -mt-1 '>
              <input
                type='text'
                className='w-full pr-20 bg-gray-200 input text-black'
                placeholder='Search'
                value={text}
                onChange={handleChange}
              />

              <div className="flex items-center">
                <div className="w-10/12">

                  <label htmlFor="stages">Stage(s):</label>
                  <select
                          name="cars"
                          id="stages"
                          onChange={handleChangeStage}
                          defaultValue="1853"
                  >
                    <option value="">(all) Search by Name</option>
                    <option value="1853">Ready to Order</option>            {/* (R): UF_CRM_1670943824 1763 (S): UF_CRM_1670944138 1773 (W): UF_CRM_1670944194 1783 (WW) UF_CRM_1670944299 1793 (G) UF_CRM_1670944392 1803 (F) UF_CRM_1670944510 1813 (D) 1823 (I) 1833 (O) 1843 */}
                    <option value="1855">Materials Ordered</option>      {/* (R): UF_CRM_1670943824 1765 (S): UF_CRM_1670944138 1775 (W): UF_CRM_1670944194 1785 (WW) UF_CRM_1670944299 1795 (G) UF_CRM_1670944392 1805 (F) UF_CRM_1670944510 1815 (D) 1825 (I) 1835 (O) 1845 */}
                    <option value="1857">Materials Delivered</option>  {/* (R): UF_CRM_1670943824 1767 (S): UF_CRM_1670944138 1777 (W): UF_CRM_1670944194 1787 (WW) UF_CRM_1670944299 1797 (G) UF_CRM_1670944392 1807 (F) UF_CRM_1670944510 1817 (D) 1827 (I) 1837 (O) 1847 */}
                    <option value="1859">Crew Scheduled</option>            {/* (R): UF_CRM_1670943824 1769 (S): UF_CRM_1670944138 1779 (W): UF_CRM_1670944194 1789 (WW) UF_CRM_1670944299 1799 (G) UF_CRM_1670944392 1809 (F) UF_CRM_1670944510 1819 (D) 1829 (I) 1839 (O) 1849 */}
                    <option value="1861">Completed</option>                      {/* (R): UF_CRM_1670943824 1771 (S): UF_CRM_1670944138 1781 (W): UF_CRM_1670944194 1791 (WW) UF_CRM_1670944299 1801 (G) UF_CRM_1670944392 1811 (F) UF_CRM_1670944510 1821 (D) 1831 (I) 1841 (O) 1851 */}
                  </select>

                </div>
                <div className="w-2/12">

                  <Switch onChange={handleChangeView} />

                </div>
              </div>




{/*
              <label htmlFor="stages">Only partly assigned:</label>
              <Checkbox
                value={onlyPartlyAssigned}
                onChange={handleChangeOnlyPartlyAssigned}
              />
*/}



              <button
                type='submit'
                className='absolute top-0 right-0 rounded-l-none w-30 btn'
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>

{/*
      {users.length > 0 && (
        <div>
          <button
            onClick={() => dispatch({ type: 'CLEAR_USERS' })}
            className='btn btn-ghost btn-lg'
          >
            Clear
          </button>
        </div>
      )}
*/}

    </div>
  )
}

export default DealSearch
