import * as React from 'react';
import axios from 'axios'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";
import DealItem from "./DealItem";
import {useEffect, useContext} from "react";
import {Draggable} from "@fullcalendar/interaction";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ECContext from '../../context/deal-assignments/ECContext'

import TransferPhotoList from "../buildDateDialog/TransferPhotoList";
import {getPhotos, getSubContractors, getCalendarEvents, searchUsers} from "../../context/deal-assignments/ECActions";
import Spinner from "../layout/Spinner";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { green } from '@mui/material/colors';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


function NewBuildDateDlg() {
    //const [show, setShow] = React.useState(showDialog);

    const phpServer = axios.create({})

    const {
       showBuildDateDialog,
       deal,
       subs,
       photos,
       selectedPhotos,
       dispatch,
       savingDialogInProgress,
       //checkedTradeIDs
    } = useContext(ECContext)

    const [tradeType, setTradeType] = React.useState([]);
    const [crew, setCrew] = React.useState('');
    const [buildDate, setBuildDate] = React.useState('');
    const [materialColor, setMaterialColor] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [dumpsterLocation, setDumpsterLocation] = React.useState('');

    const [projectComments, setProjectComments] = React.useState('');
    const [curSelectedPhotos, setCurSelectedPhotos] = React.useState({});

    const [openError, setOpenError] = React.useState(false);
    const [emptySubError, setEmptySubError] = React.useState(false);

   const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });




    const [dealData, setDealData] = React.useState({});
    const [subsData, setSubsData] = React.useState([]);

    const handleChangeTradeType = (event) => {
        setTradeType(event.target.value);
    };
    const handleChangeCrew = (event) => {
        setCrew(event.target.value);
    };

    const handleChangeBuildDate = (event) => {
        setBuildDate(event.target.value)
    }

    const handleChangeProjectComments = (event) => {
        setProjectComments(event.target.value)
    }


    const label = { inputProps: { 'aria-label': 'Switch demo' } };


    // useEffect(() => {
    //
    //     console.log(dealData)
    //
    //     //setShow(showDialog)
    //
    // }, []);

    useEffect(async () => {

        setDealData(deal)

     // console.log("NewBuildDateDialog useEffect – dealData:")
     // console.log(deal)

        setSubsData(subs)

        setBuildDate(deal.buildDate)
        setDumpsterLocation(deal.UF_CRM_1616043895752)

       //let materialColor = ''
       // if(deal.UF_CRM_1610705431) materialColor += 'R:'+deal.UF_CRM_1610705431
       // if(deal.UF_CRM_1610877081) materialColor += 'S:'+deal.UF_CRM_1610877081
       // if(deal.UF_CRM_1610877278) materialColor += 'F:'+deal.UF_CRM_1610877278
       // if(deal.UF_CRM_1610877396) materialColor += 'G:'+deal.UF_CRM_1610877396
       // if(deal.UF_CRM_1610877841) materialColor += 'D:'+deal.UF_CRM_1610877841
       // if(deal.UF_CRM_1610877952) materialColor += 'I:'+deal.UF_CRM_1610877952
       // if(deal.UF_CRM_1610877952) materialColor += 'WW:'+deal.UF_CRM_1610877952



       //let _notes = ''
       // if(deal.UF_CRM_1610705445) _notes += 'R:'+deal.UF_CRM_1610705445+' | '
       // if(deal.UF_CRM_1610877094) _notes += 'S:'+deal.UF_CRM_1610877094+' | '
       // if(deal.UF_CRM_1610877407) _notes += 'F:'+deal.UF_CRM_1610877407+' | '
       // if(deal.UF_CRM_1610877585) _notes += 'G:'+deal.UF_CRM_1610877585+' | '
       // if(deal.UF_CRM_1610877850) _notes += 'D:'+deal.UF_CRM_1610877850+' | '
       // if(deal.UF_CRM_1610877963) _notes += 'I:'+deal.UF_CRM_1610877963+' | '
       // if(deal.UF_CRM_1611841888) _notes += 'WW:'+deal.UF_CRM_1611841888+' | '



       // (R): UF_CRM_1610705431
       // (S): UF_CRM_1610877081
       // (F): UF_CRM_1610877278
       // (G): UF_CRM_1610877396
       // (D): UF_CRM_1610877841
       // (I): UF_CRM_1610877952
       // (WW): UF_CRM_1610877952

        let materialColor = ''
        let _notes = ''




//setTradeType([])


/*        switch (deal.tradeId) {
            case '44':
                deal.tradeId = 10 //ROOF
                _notes = deal.UF_CRM_1610705445
                materialColor = deal.UF_CRM_1610705431
                break
            case '46':
                deal.tradeId = '' //"(RDG)"
                break
            case '48':
                deal.tradeId = '' //"(RS)"
                break;
            case '50':
                deal.tradeId = 20 //(S)
                _notes = deal.UF_CRM_1610877094
                materialColor = deal.UF_CRM_1610877081
                break;
            case '52':
                deal.tradeId = 30 // "(W)"
                break
            case '54':
                deal.tradeId = 50 // G
                _notes = deal.UF_CRM_1610877585
                materialColor = deal.UF_CRM_1610877396
                break
            case '56':
                deal.tradeId = 60 // F
                _notes = deal.UF_CRM_1610877407
                materialColor = deal.UF_CRM_1610877278
                break
            case '58':
                deal.tradeId = 70 // D
                _notes = deal.UF_CRM_1610877850
                materialColor = deal.UF_CRM_1610877841
                break
            case '60':
                deal.tradeId = 80 // I
                _notes = deal.UF_CRM_1610877963
                materialColor = deal.UF_CRM_1610877952
                break
            case '319':
                deal.tradeId = 40 //WW
                _notes = deal.UF_CRM_1611841888
                materialColor = deal.UF_CRM_1610877952
                break
            case '62':
                deal.tradeId = 90 // (OTHR)
                break
            case '669':
                deal.tradeId = ''
                break //(OOP)
        }*/

      //const ttt = deal.tradeId[0];


       if(deal.tradeId == undefined)
          return;

 // console.log('Incoming deal.tradeId:')
 // console.log(deal.tradeId)


      let convertedTradeIDs = []
//       convertedTradeIDs.push()

       deal.tradeId.forEach((tradeId) => {

          switch (tradeId) {
             case '44':
                convertedTradeIDs.push(10) //ROOF
                _notes = deal.UF_CRM_1610705445
                materialColor = deal.UF_CRM_1610705431

                break
             case '46':
                deal.tradeId = '' //"(RDG)"
                break
             case '48':
                deal.tradeId = '' //"(RS)"
                break;
             case '50':
                convertedTradeIDs.push(20) //(S)
                _notes = deal.UF_CRM_1610877094
                materialColor = deal.UF_CRM_1610877081

                break;
             case '52':
                convertedTradeIDs.push(30) // "(W)"
                break
             case '54':
                convertedTradeIDs.push(50) // G
                _notes = deal.UF_CRM_1610877585
                materialColor = deal.UF_CRM_1610877396

                break
             case '56':
                convertedTradeIDs.push(60) // F
                _notes = deal.UF_CRM_1610877407
                materialColor = deal.UF_CRM_1610877278

                break
             case '58':
                convertedTradeIDs.push(70) // D
                _notes = deal.UF_CRM_1610877850
                materialColor = deal.UF_CRM_1610877841

                break
             case '60':
                convertedTradeIDs.push(80) // I
                _notes = deal.UF_CRM_1610877963
                materialColor = deal.UF_CRM_1610877952

                break
             case '319':
                convertedTradeIDs.push(40) //WW
                _notes = deal.UF_CRM_1611841888
                materialColor = deal.UF_CRM_1610877952

                break
             case '62':
                convertedTradeIDs.push(90) // (OTHR)
                break
             case '669':
                break //(OOP)
          }
       })

       setNotes(_notes)
       setMaterialColor(materialColor);


       const trades = []
        trades.push(...convertedTradeIDs)
        setTradeType(trades)

       // console.log('Incoming converted trades:')
       // console.log(convertedTradeIDs)


       console.log('trades for the combobox:')
       console.log(trades)



       setCrew('')
       setProjectComments('')

        // console.log("NewBuildDateDialog useEffect – subsData:")
        // console.log(subs)


        // console.log("showBuildDateDialog:")
        // console.log(showBuildDateDialog)
        //setShow(showDialog)

       if(showBuildDateDialog === true) {

          dispatch({type: 'SET_DIALOG_LOADING'})
          const photos = await getPhotos(deal.ID)
          dispatch({type: 'GET_PHOTOS', payload: photos})
       }



    }, [showBuildDateDialog]);

    useEffect(() => {

        setCurSelectedPhotos(selectedPhotos)

    }, [selectedPhotos]);

    const handleClickOpen = () => {
        //setShow(true);
        //show = true
    };

    const handleCreatCalendarEvent = async () => {

// не даем создавать, если не выбрана команда

      if(crew.length == 0) {

         setEmptySubError(true);

         return;
      }




       dispatch({ type: 'SET_SAVING_DIALOG_IN_PROGRESS' })

        // const params = new URLSearchParams({
        //     q: text,
        //     stage: stage
        // })
        //const response = await phpServer.get(`https://dev.blueocean24.ru/temp/ec_create_update_calendar_event.php?${params}`)
        //const response = await phpServer.get(`https://dev.blueocean24.ru/temp/ec_create_update_calendar_event.php`)

        //dispatch({ type: 'SET_LOADING' })




// *** =============== [Check if we can create trade with selected tradeType (we can't if there's trade with this deal)]

 /*      const dataForCheck = {
          tradeType: tradeType,
          dealId: deal.dealId,
       }


       console.log("data (check) to send:")
       console.log(dataForCheck)

       const urlCheck = 'https://cloud.blueocean24.ru/backend/ec_check_trade.php'
       const responseCheck = await fetch(urlCheck, {
          method: 'POST',         // *GET, POST, PUT, DELETE, etc.
          mode: 'same-origin',        // no-cors, *cors, same-origin
          cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit
          headers: {
             'Content-Type': 'application/json'
          },
          redirect: 'follow',     // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(dataForCheck) // body data type must match "Content-Type" header
       });

       console.log("Response result (check):")
       const incomeData = await responseCheck.json()
       console.log(incomeData)

      if (incomeData.code === 0) {

         //const errorMessage = incomeData.message

         setOpenError(true)
         dispatch({ type: 'DROP_SAVING_DIALOG_IN_PROGRESS' })

         return;
      }
*/








        let tmpSelectedPhotos = []
        tmpSelectedPhotos = Object.keys(selectedPhotos).map((key) => {
            return {id: Number(key), value: selectedPhotos[key]}
        });

        // console.log("selected phots (object):")
        // console.log(selectedPhotos)

        console.log("photos:")
        console.log(photos)


        console.log("selected phots (array):")
        console.log(tmpSelectedPhotos)

        const photosBase64 = []
        for (const photoItem of tmpSelectedPhotos) {

            console.log("photo data:")
            console.log(photoItem)

            if(photoItem.value === true) {

                const url = photos.filter( (item) => item.fileId === photoItem.id.toString())[0].url
                const fileName = photos.filter( (item) => item.fileId === photoItem.id.toString())[0].fileName

                console.log("url:")
                console.log(url)

                //const base64 = await getBase64FromUrl(url)
                //const base64 = await getBase64FromUrl('https://lh3.googleusercontent.com/i7cTyGnCwLIJhT1t2YpLW-zHt8ZKalgQiqfrYnZQl975-ygD_0mOXaYZMzekfKW_ydHRutDbNzeqpWoLkFR4Yx2Z2bgNj2XskKJrfw8')

                //let base64 = getBase64Image(document.getElementById(photoItem.id.toString()));

                // toDataUrl(url, function(myBase64) {
                //     console.log(myBase64); // myBase64 is the base64 string
                // });


                //photosBase64.push({id: photoItem.id, base64: base64})
                photosBase64.push({id: photoItem.id, url: url, fileName: fileName})
                //photosBase64.push(photoItem.id)


            }



            // const base64 = getBase64Image(document.getElementById(photoItem.id));
            // photosBase64.push({id: photoItem.id, base64: base64})
        }


        const data = {
            tradeType: tradeType,
            crew: crew,
            buildDate: buildDate,
            projectComments: projectComments,
            dealId: deal.dealId,

            selectedPhotos: photosBase64
        }


console.log("data to send:")
console.log(data)

//return

        const url = 'https://dev.blueocean24.ru/temp/ec_create_update_calendar_event.php'
        const response = await fetch(url, {
            method: 'POST',         // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors',        // no-cors, *cors, same-origin
            cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow',     // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });

        console.log("Response result:")
        //console.log(response.json())
        console.log(response)



//Refreshing the calendar

        const calendarEvents = await getCalendarEvents()
        dispatch({ type: 'GET_CALENDAR_EVENTS', payload: calendarEvents })


       dispatch({ type: 'DROP_SAVING_DIALOG_IN_PROGRESS' })

        dispatch({ type: 'SET_HIDE_DIALOG' })



    }

    const handleClose = () => {
        dispatch({ type: 'SET_HIDE_DIALOG' })
    };

   const handleCloseAlert = () => {
      //dispatch({ type: 'SET_HIDE_DIALOG' })
      setOpenError(false)
   };

   const handleEmptySubAlert = () => {
      //dispatch({ type: 'SET_HIDE_DIALOG' })
      setEmptySubError(false)
   };


   const buttonSx = {
      ...(savingDialogInProgress && {
         bgcolor: green[500],
         '&:hover': {
            bgcolor: green[700],
         },
      }),
   };


   const [success, setSuccess] = React.useState(false);



    return (
        <div>

            <Dialog open={showBuildDateDialog}
                    onClose={handleClose}
            >


               <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseAlert}>
                  <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                     Can't create new BuildDate!
                  </Alert>
               </Snackbar>

               <Snackbar open={emptySubError} autoHideDuration={6000} onClose={handleEmptySubAlert}>
                  <Alert onClose={handleEmptySubAlert} severity="error" sx={{ width: '100%' }}>
                     Crew field is not specified
                  </Alert>
               </Snackbar>



               <DialogTitle>New Build Date</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>

                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                        <InputLabel id="trade-type-label">Trade Type</InputLabel>
                                        <Select
                                           labelId="trade-type-label"
                                           id="trade-type-select"
                                           value={tradeType}
                                           onChange={handleChangeTradeType}
                                           label="Trade Type"


                                           multiple
                                        >

                                            <MenuItem value={10}>Roof</MenuItem>
                                            <MenuItem value={20}>Siding</MenuItem>
                                            <MenuItem value={30}>Windows</MenuItem>
                                            <MenuItem value={40}>Window Wraps</MenuItem>
                                            <MenuItem value={50}>Gutters</MenuItem>
                                            <MenuItem value={60}>Fascia</MenuItem>
                                            <MenuItem value={70}>Deck</MenuItem>
                                            <MenuItem value={80}>Interior</MenuItem>
                                            <MenuItem value={90}>Other</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <InputLabel id="crew-label">Crew</InputLabel>
                                    <Select
                                    labelId="crew-label"
                                    id="crew"
                                    value={crew}
                                    onChange={handleChangeCrew}
                                    label="Crew"
                                    >

                                {subsData.map((sub) => (
                                    <MenuItem key={sub.ID} value={sub.ID}>{sub.NAME}</MenuItem>
                                    ))}


                                    {/*<MenuItem value={10}>Crew1</MenuItem>*/}
                                    {/*<MenuItem value={20}>Crew2</MenuItem>*/}
                                    {/*<MenuItem value={30}>Crew3</MenuItem>*/}
                                    {/*<MenuItem value={40}>Crew4</MenuItem>*/}
                                    {/*<MenuItem value={50}>Crew5</MenuItem>*/}
                                    </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                    <TextField
                                    margin="dense"
                                    id="buildDate"
                                    label="Build Date"
                                    type="date"
                                    fullWidth
                                    variant="standard"
                                    value={buildDate}/*{deal.buildDate}*/
                                    onChange={handleChangeBuildDate}
                                    />
                                    </FormControl>

                                    <FormControl variant="standard" sx={{minWidth: 450}}>
                                    <TextField
                                    id="projectComments"
                                    label="Project Comments"
                                    variant="standard"
                                    onChange={handleChangeProjectComments}
                                    />
                                    </FormControl>

                                    <br/>
                                    <br/>
                                {/*
                                        <FormControl variant="standard" sx={{ minWidth: 450 }}>
                                            <FormControlLabel control={<Switch id="statusCompleted"/>} label="Status Completed" />
                                        </FormControl>
                                        <FormControl variant="standard" sx={{ minWidth: 450 }}>
                                            <FormControlLabel control={<Switch id="permitClose"/>} label="Permit Close" />
                                        </FormControl>
                                */}

                                    <FormControl variant="standard" sx={{minWidth: 450}}>
                                {/*<TextField id="permitNotes" label="Permit Notes" variant="standard" />*/}
                                    <TextField id="materialColor" label="Material Color" variant="standard" disabled={true} value={materialColor} />
                                    <TextField id="notes" label="Notes" variant="standard" disabled={true} value={notes}/>
                                    <TextField id="dumpsterLocation" label="Dumpster Location" variant="standard" disabled={true} value={dumpsterLocation}/>

                                    </FormControl>

                                    <br/>
                                    <br/>

                                    <TransferPhotoList/>

                </DialogContent>
                <DialogActions>

                    {/*<Button onClick={handleCreatCalendarEvent}>Create</Button>*/}
                   <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                         variant="contained"
                         sx={buttonSx}
                         disabled={savingDialogInProgress}
                         onClick={handleCreatCalendarEvent}
                      >
                         Create
                      </Button>
                      {savingDialogInProgress && (
                         <CircularProgress
                            size={24}
                            sx={{
                               color: green[500],
                               position: 'absolute',
                               top: '50%',
                               left: '50%',
                               marginTop: '-12px',
                               marginLeft: '-12px',
                            }}
                         />
                      )}
                   </Box>
                    <Button onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

// NewBuildDateDlg.propTypes = {
//     showDialog: PropTypes.bool
// }


export default NewBuildDateDlg





/*
function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}
*/

/*
var base64 = getBase64Image(document.getElementById("imageid"));
*/

/*const getBase64FromUrl = async (url) => {
    const data = await fetch(url);

    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}*/


/*function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}*/

/*
toDataUrl(imagepath, function(myBase64) {
    console.log(myBase64); // myBase64 is the base64 string
});
*/


