import {useContext, useEffect, useState} from "react";
import ECContext from "../../context/deal-assignments/ECContext";
import {getTradesForKanban, updateTradeStatusFromKanban} from "../../context/deal-assignments/ECActions";

import ReactDOM from "react-dom";
import Board, { moveCard } from "@lourenci/react-kanban";
//import "@lourenci/react-kanban/dist/styles.css";
import './styles.css'
import Spinner from "../layout/Spinner";

import DealItem from  '../deals/DealItem'
// https://github.com/asseinfo/react-kanban

const board =
    {
        columns: [
            // {
            //     id: 6,
            //     title: "Others",
            //     cards: []
            // }
        ]
    }


const items = [];



/*function UncontrolledBoard() {
    return (
        <Board
            allowRemoveLane
            allowRenameColumn
            allowRemoveCard
            onLaneRemove={console.log}
            onCardRemove={console.log}
            onLaneRename={console.log}
            initialBoard={board}
            allowAddCard={{ on: "top" }}
            onNewCardConfirm={(draftCard) => ({
                id: new Date().getTime(),
                ...draftCard
            })}
            onCardNew={console.log}
        />
    );
}*/


function KanbanTrades() {

    const { tradesForKanban, loading, dispatch } = useContext(ECContext)
    //const [controlledBoard, setBoard] = useState(board);
    const [controlledBoard, setBoard] = useState(board);


    useEffect(async () => {

       dispatch({ type: 'SET_LOADING' })

       const kanbanTrades = await getTradesForKanban()

       // console.log('kanbanTrades data:')
       // console.log(kanbanTrades)

       dispatch({ type: 'SET_TRADES_FOR_KANBAN', payload: kanbanTrades })


        //dispatch({ type: 'SET_LOADING' })

        // const kanbanTrades = await getTradesForKanban()
        // dispatch({ type: 'SET_TRADES_FOR_KANBAN', payload: kanbanTrades })
        //
        //setBoard(tradesForKanban)

        // console.log('In useEffect. Running setBoard(tradesForKanban) with: ')
        // console.log(tradesForKanban)
        // console.log('loading: ')
        // console.log(loading)
        //
        // if(!loading && tradesForKanban.columns.length > 1)
        //     setBoard(tradesForKanban)


    }, []);



    const getDealTradeStatusField = (tradeId) => {

        switch (tradeId) {
            case 44: return "UF_CRM_1670943824"           //Roof
            case 50: return "UF_CRM_1670944138"           //Siding
            case 52: return "UF_CRM_1670944194"           //Windows
            case 54: return "UF_CRM_1670944392"           //Gutters
            case 56: return "UF_CRM_1670944510"           //Fascia
            case 58: return "UF_CRM_1670944578"           //Deck
            case 60: return "UF_CRM_1670944681"           //Interior
            case 319: return "UF_CRM_1670944299"          //Window Wraps
            default:
                return ''
        }
    }


    const getTradeDestStatus = (column, tradeId) => {

        switch (column) {
            case 1: { // 'Ready to Order'

               switch (tradeId) {
                  case 44: return "1763"           //Roof
                  case 50: return "1773"           //Siding
                  case 52: return "1783"           //Windows
                  case 54: return "1803"           //Gutters
                  case 56: return "1813"           //Fascia
                  case 58: return "1823"           //Deck
                  case 60: return "1833"           //Interior
                  case 319: return "1793"          //Window Wraps
                  default:
                     return ''
               }

               return ''
            }
            // case 4: { // 'Crew Scheduled'
            //
            //     return ''
            // }
            case 5: { // 'Completed

               switch (tradeId) {
                  case 44: return "1771"           //Roof
                  case 50: return "1781"           //Siding
                  case 52: return "1791"           //Windows
                  case 54: return "1811"           //Gutters
                  case 56: return "1821"           //Fascia
                  case 58: return "1831"           //Deck
                  case 60: return "1841"           //Interior
                  case 319: return "1801"          //Window Wraps
                  default:
                     return ''
               }


                return ''
            }
            default:
                return ''
        }
    }

    const getStatusCompleteField = (tradeId) => {

       switch (tradeId) {
          case 44: return "UF_CRM_1610705377"           //Roof
          case 50: return "UF_CRM_1610877032"           //Siding
          case 52: return "UF_CRM_1610877539"           //Windows
          case 54: return "UF_CRM_1610877386"           //Gutters
          case 56: return "UF_CRM_1610877262"           //Fascia
          case 58: return "UF_CRM_1610877830"           //Deck
          case 60: return "UF_CRM_1610877941"           //Interior
          case 319: return "UF_CRM_1611841854"          //Window Wraps
          default:
             return ''
       }
    }




    if(!loading && Object.keys(tradesForKanban).length > 0) {

       // if(Object.keys(tradesForKanban).length == 0)
       //    setBoard(tradesForKanban)


        function handleTradeCardMove(_card, source, destination) {

           if(source.fromColumnId == 1 && destination.toColumnId == 4) {

              destination.toColumnId = 1;
              destination.toPosition = source.fromPosition;

           }

           // console.log('card:')
           // console.log(_card)
           // console.log('source:')
           // console.log(source)
           // console.log('destination:')
           // console.log(destination)


            const updatedBoard = moveCard(tradesForKanban, source, destination);

           setBoard(updatedBoard);
           dispatch({ type: 'SET_TRADES_FOR_KANBAN', payload: updatedBoard })


            const dealTradeNewStatus = getTradeDestStatus(destination.toColumnId, _card.content.tradeId)
            const dealTradeField = getDealTradeStatusField(_card.content.tradeId)
            const dealStatusCompletedField = getStatusCompleteField(_card.content.tradeId)
            let dealStatusCompletedValue = ''

           if(source.fromColumnId == 5)
              dealStatusCompletedValue = 0
           else if(destination.toColumnId == 5)
              dealStatusCompletedValue = 1

           // console.log('dealTradeField:')
           // console.log(dealTradeField)
           // console.log('dealTradeNewStatus:')
           // console.log(dealTradeNewStatus)


            if(dealTradeField !== '' && dealTradeNewStatus !== ''
               && (source.fromColumnId == 5 || destination.toColumnId == 5)) { // делаем только когда переносят в/из стадии 'Completed'

                const resUpdate = updateTradeStatusFromKanban(
                    _card.content.linkedDealId,
                    dealTradeField,
                    dealTradeNewStatus,
                   dealStatusCompletedField,
                   dealStatusCompletedValue
                )
            }


        }

        const getTradeTypeText = (tradeId) => {
            switch (tradeId) {
                case 44: return "(R)"
                case 46: return "(RDG)"
                case 48: return "(RS)"        //Roof (shed)
                case 50: return "(S)"
                case 52: return "(W)"
                case 54: return "(G)"
                case 56: return "(F)"
                case 58: return "(D)"
                case 60: return "(I)"
                case 319: return "(WW)"
                case 62: return "(OTHR)"
                case 669: return "(OOP)"
            }
        }

        const getTradeTypeColor = (tradeId) => {
            switch (tradeId) {
                case 44: return "bg-red-200"        //Roof
                case 46: return "bg-pink-200"        //Roof (detached garage)
                case 48: return "bg-pink-400"        //Roof (shed)
                case 50: return "bg-blue-200"       //Siding
                case 52: return "bg-green-200"       //Windows
                case 54: return "bg-gray-200"       //Gutters
                case 56: return "bg-purple-200"       //Fascia
                case 58: return "bg-yellow-200"     //Deck
                case 60: return "bg-stone-200"      //Interior
                case 319: return "bg-blue-400"   //Window Wraps
                case 62: return ""   //Other
                case 669: return ""   //Out of Pocket
                default: return ""
            }
        }

       const makeCompletedBold = (tradeId, content) => {

//          console.log('tradeId:' + tradeId + 'value:' + prop.trades.trade.linkedDealData.UF_CRM_1670943824)

          if(tradeId == 44 && content.linkedDealData.UF_CRM_1670943824 == 1771) //Roof
             return ' font-semibold '
          if(tradeId == 46 && content.linkedDealData.UF_CRM_1670943824 == 1771)
             return ' font-semibold '
          if(tradeId == 48 && content.linkedDealData.UF_CRM_1670943824 == 1771)
             return ' font-semibold '
          if(tradeId == 50  && content.linkedDealData.UF_CRM_1670944138 == 1781) //Siding
             return ' font-semibold '
          if(tradeId == 52  && content.linkedDealData.UF_CRM_1670944194 == 1791) //Windows
             return ' font-semibold '
          if(tradeId == 54  && content.linkedDealData.UF_CRM_1670944392 == 1811) //Gutters
             return ' font-semibold '
          if(tradeId == 56  && content.linkedDealData.UF_CRM_1670944510 == 1821) //Fascia
             return ' font-semibold '
          if(tradeId == 58  && content.linkedDealData.UF_CRM_1670944578 == 1831) //Deck
             return ' font-semibold '
          if(tradeId == 60  && content.linkedDealData.UF_CRM_1670944681 == 1841) //Interior
             return ' font-semibold '
          if(tradeId == 319  && content.linkedDealData.UF_CRM_1670944299 == 1801) //Window Wraps
             return ' font-semibold '

          return ''
       }


        return (
            <Board onCardDragEnd={handleTradeCardMove} disableColumnDrag
                   renderCard = { ({ content }, { removeCard, dragging }) => (


                    <>

                    <div className='grid grid-cols-1 gap-1 py-0.5'>

                       <div id={`${content.id}`} draggable={false} className='card w-64 shadow-md compact side bg-base-100 ' >
                           <div className='flex-row items-center space-x-4 card-body whitespace-normal '>

                                <div >
                                   <a href={`https://estateclaim.bitrix24.com/crm/deal/details/${content.linkedDealId}/`} target="_blank">
                                       <h2 className={'text-sm rounded ' + getTradeTypeColor(content.tradeId)}>{getTradeTypeText(content.tradeId) + ' ' + content.linkedDealTitle}</h2>
                                   </a>
                                   <div className='flex mt-1 font-thin text-7 '>
                                       Related:

                                       {content.linkedTrades.map((tradeId) => (
                                           <div className={'flex rounded-md ml-1 ' + getTradeTypeColor(tradeId) + ' ' + makeCompletedBold(tradeId, content)}>
                                               {getTradeTypeText(tradeId)}
                                           </div>
                                       ))}
                                    </div>

                                   <div>{content.tradeStatusDate}</div>
                                </div>

                           </div>
                       </div>

                    </div>

                    </>



                       /*
                                          <YourCard dragging={dragging}>
                                              {content}
                                              <button type="button" onClick={removeCard}>Remove Card</button>
                                          </YourCard>
                       */
                   )}
            >
                {tradesForKanban}
            </Board>
        );

    }
    else {
        return <Spinner/>
    }




}

KanbanTrades.propTypes = {
  //deal: PropTypes.object.isRequired,
}

export default KanbanTrades
